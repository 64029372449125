import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import fp from 'lodash/fp';

Vue.prototype.uniqueId = fp.uniqueId;

Vue.prototype.log = fp.tap(fp.pipe([
  JSON.stringify,
  fp.cond([[fp.identity, JSON.parse]]),
  result => window.console.log(result),
]));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
