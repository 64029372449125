<template lang='pug'>
  div(style='display:none')
    ReadVoxel(
      :boxes='ModelRotator'
      :isPickable='true'
      :scene='scene'
      :shadowGenerator='shadowGenerator'
      @pick='rotate'
      @update:mesh='alignMeshes'
      @update:transformNode='transformNode => transformNode.parent = transformNodeRotator'
    )
    slot(
      v-if='transformNode'
      :transformNode='transformNode'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import fp from 'lodash/fp';
import * as BABYLON from 'babylonjs';
import ReadVoxel from '@/components/ReadVoxel.vue';

const ModelRotator = require('../../ModelRotator.json');

const mounted = function ()
{
  this.transformNode.setPivotPoint
  (
    new BABYLON.Vector3
    (
      this.transformNodeJson.pivotPoint.x,
      this.transformNodeJson.pivotPoint.y,
      this.transformNodeJson.pivotPoint.z,
    )
  );

  this.transformNode.rotation = this.rotation;

  this.transformNodeRotator.parent = this.transformNodeChild;
};

const degrees = 90;

const radians = function ()
{
  return BABYLON.Tools.ToRadians(this.degrees);
};

const degreesToRadians = function (degrees)
{
  return BABYLON.Tools.ToRadians(degrees);
};

const pivotPoint = function ()
{
  return fp.pipe
  ([
    fp.get('mesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize'),
    ({ x, y, z }) => ({ x: y, y, z }),
  ])
  (this);
};

const transformNodeJson = function ()
{
  return fp.pipe
  ([
    fp.at(['pivotPoint', 'transformNode.rotation']),
    ([pivotPoint, rotation]) => ({ pivotPoint, rotation }),
    JSON.stringify,
    JSON.parse,
  ])
  (this);
};

const destroyed = function ()
{
  this.transformNode.dispose();
};

const rotate = function ()
{
  this.transformNode.addRotation(0, 0, this.radians);

  this.$emit('update:transformNodeJson', this.transformNodeJson);
};

const alignMeshes = function (meshRotator)
{
  this.transformNodeRotator.position = new BABYLON.Vector3
  (
    this.pivotPoint.x,
    this.pivotPoint.y,
    this.pivotPoint.z,
  );

  this.transformNodeRotator.position = fp.pipe
  ([
    fp.get('transformNodeRotator.position'),
    fp.invokeArgs('subtract')([new BABYLON.Vector3(1, 1, 0)]),
    fp.invokeArgs('multiply')([new BABYLON.Vector3(1, 1, 0)]),
  ])
  (this);

  this.transformNodeRotator.rotation.x = this.degreesToRadians(270);
};

export default
{
  components:
  {
    ReadVoxel
  },
  computed:
  {
    pivotPoint,
    radians,
    transformNodeJson,
  },
  data()
  {
    return {
      degrees,
      ModelRotator,
      transformNode: new BABYLON.TransformNode(this.uniqueId(), this.scene),
      transformNodeRotator: new BABYLON.TransformNode(this.uniqueId(), this.scene),
    };
  },
  destroyed,
  methods:
  {
    alignMeshes,
    degreesToRadians,
    rotate,
  },
  mounted,
  props:
  {
    mesh: { required: true },
    rotation: { required: true },
    scene: { required: true },
    shadowGenerator: { required: true },
    transformNodeChild: { required: true },
  },
}
</script>