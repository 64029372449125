<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const created = async function () {
  this.light = new BABYLON.PointLight
  (
    this.uniqueId(),
    new BABYLON.Vector3(this.x, this.y, this.z),
    this.scene,
  );

  this.light.intensity = 0.001;
};

const destroyed = function () {
  this.light.dispose();
};

export default {
  components: {
    //
  },
  created,
  data() {
    return {
      light: null,
    };
  },
  destroyed,
  props: {
    scene: {
      required: true
    },
    x: {
      default: 0,
      required: false,
    },
    y: {
      default: 0,
      required: false,
    },
    z: {
      default: 0,
      required: false,
    },
    rProp: {
      default: 0,
      required: false,
    },
    gProp: {
      default: 0,
      required: false,
    },
    bProp: {
      default: 0,
      required: false,
    },
  },
  watch: {
    x: {
      immediate: false,
      handler() {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
    y: {
      immediate: false,
      handler() {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
    z: {
      immediate: false,
      handler() {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
    rProp: {
      immediate: false,
      handler() {
        this.light.diffuse.set(this.rProp, this.gProp, this.bProp);
        this.light.specular.set(255, 255, 0);
      },
    },
    gProp: {
      immediate: false,
      handler() {
        this.light.diffuse.set(this.rProp, this.gProp, this.bProp);
        this.light.specular.set(255, 255, 0);
      },
    },
    bProp: {
      immediate: false,
      handler() {
        this.light.diffuse.set(this.rProp, this.gProp, this.bProp);
        this.light.specular.set(255, 255, 0);
      },
    },
    light: {
      immediate: false,
      handler() {
        this.light.diffuse.set(this.rProp, this.gProp, this.bProp);
        this.light.specular.set(255, 255, 0);
      },
    },
  }
}
</script>