<template lang='pug'>
  div(style='display:none')
    slot(
      :w='w'
      :a='a'
      :s='s'
      :d='d'
    )
</template>

<script>
import * as BABYLON from 'babylonjs';

const created = function () {
  this.scene.actionManager = new BABYLON.ActionManager(this.scene);

  this.scene.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnKeyDownTrigger,
      event => {
        this.$emit(`${event.sourceEvent.key}KeyDown`);

        this[event.sourceEvent.key] = true;
      },
    )
  );

  this.scene.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnKeyUpTrigger,
      event => {
        this.$emit(`${event.sourceEvent.key}KeyUp`);

        this[event.sourceEvent.key] = false;
      },
    )
  );
};

export default {
  created,
  data() {
    return {
      w: false,
      a: false,
      s: false,
      d: false,
    };
  },
  props: {
    scene: { required: true }
  },
}
</script>