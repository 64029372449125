<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const mounted = async function ()
{
  this.light = new BABYLON.HemisphericLight
  (
    this.uniqueId(),
    new BABYLON.Vector3(this.x, this.y, this.z),
    this.scene,
  );

  this.light.diffuse = new BABYLON.Color3(0, 0.1, 0.1);
  this.light.groundColor = new BABYLON.Color3(0.1, 0.1, 0);
};

const destroyed = function ()
{
  this.light.dispose();
};

export default
{
  mounted,
  data()
  {
    return {
      light: null,
    };
  },
  destroyed,
  props: {
    scene: {
      required: true
    },
    x: {
      default: -1,
      required: false,
    },
    y: {
      default: 1,
      required: false,
    },
    z: {
      default: 0,
      required: false,
    },
  },
  watch: {
    x: {
      immediate: false,
      handler() {
        this.light.direction.set(this.x, this.y, this.z);
      },
    },
    y: {
      immediate: false,
      handler() {
        this.light.direction.set(this.x, this.y, this.z);
      },
    },
    z: {
      immediate: false,
      handler() {
        this.light.direction.set(this.x, this.y, this.z);
      },
    },
  }
}
</script>