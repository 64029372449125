<template lang='pug'>
  div(style='display:none')
    slot(
      v-if='transformNode'
      :transformNode='transformNode'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import fp from 'lodash/fp';
import * as BABYLON from 'babylonjs';

const mounted = function ()
{
  this.transformNodeChild.parent = this.transformNode;

  this.transformNode.setPivotPoint
  (
    new BABYLON.Vector3
    (
      this.pivotPoint.x,
      this.pivotPoint.y,
      this.pivotPoint.z,
    )
  );

  this.transformNode.rotation = this.rotation;
};

const destroyed = function ()
{
  this.transformNode.dispose();
};

export default
{
  data()
  {
    return {
      transformNode: new BABYLON.TransformNode(this.uniqueId(), this.scene)
    };
  },
  destroyed,
  mounted,
  props:
  {
    pivotPoint: { required: true },
    rotation: { required: true },
    scene: { required: true },
    transformNodeChild: { required: true },
  },
}
</script>