<template lang='pug'>
  div(style='display:none')
    slot(
      v-if='transformNode'
      :transformNode='transformNode'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import fp from 'lodash/fp';

import * as BABYLON from 'babylonjs';

const mounted = function ()
{
  this.transformNodeChild.parent = this.transformNode;

  this.transformNode.position.x = this.x;
  this.transformNode.position.y = this.y;
  this.transformNode.position.z = this.z;
};

const destroyed = function ()
{
  this.transformNode.dispose();
};

export default
{
  data()
  {
    return {
      transformNode: new BABYLON.TransformNode(this.uniqueId(), this.scene)
    };
  },
  destroyed,
  mounted,
  props:
  {
    scene: { required: true },
    transformNodeChild: { required: true },
    x:
    {
      default: 0,
      required: true,
    },
    y:
    {
      default: 0,
      required: true,
    },
    z:
    {
      default: 0,
      required: true,
    },
  },
}
</script>