var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templateRoot"},[_c('v-navigation-drawer',{attrs:{"app":"","dark":"","mini-variant":"","permanent":""}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.mode = "plus"}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ "pink--text": _vm.mode === "plus" }},[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title')],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.mode = "minus"}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ "pink--text": _vm.mode === "minus" }},[_vm._v("mdi-minus")])],1),_c('v-list-item-content',[_c('v-list-item-title')],1)],1)],1)],1),_c('canvas',{ref:"canvas",attrs:{"id":"renderCanvas","touch-action":"none"}}),(_vm.scene)?_c('div',{staticStyle:{"display":"none"}},[_c('PickClick',{attrs:{"scene":_vm.scene},on:{"result":function($event){}}}),_c('CameraUniversal',{attrs:{"canvas":_vm.canvas,"scene":_vm.scene}}),_c('Wasd',{attrs:{"scene":_vm.scene},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var w = ref.w;
var a = ref.a;
var s = ref.s;
var d = ref.d;
return [_c('Position',{attrs:{"scene":_vm.scene,"w":w,"a":a,"s":s,"d":d,"step":0.5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var x = ref.x;
var z = ref.z;
return [_c('HemisphericLight',{attrs:{"scene":_vm.scene,"x":-1,"y":1,"z":0}}),_c('LightDirectional',{attrs:{"scene":_vm.scene},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shadowGenerator = ref.shadowGenerator;
return [_vm._l((_vm.cinderBlocksInitial),function(position){return _c('ReadVoxel',{key:_vm.positionToStr(position),attrs:{"boxes":_vm.block,"scene":_vm.scene,"pivotPoint":position.pivotPoint,"rotation":position.rotation,"shadowGenerator":shadowGenerator,"isPickable":true,"texture":_vm.texture,"uvs":_vm.uvs},on:{"pick":function (event) { return _vm.blocksUpdate(position)(event); },"pickDown":function (event) { return _vm.blockNextUpdate(position)(event); },"pickUp":function($event){_vm.blockNext = null},"pickOut":function($event){_vm.blockNext = null}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodeRotationY',{attrs:{"pivotPoint":position.transformNodeY.pivotPoint,"rotation":position.transformNodeY.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodeRotationZ',{attrs:{"pivotPoint":position.transformNodeZ.pivotPoint,"rotation":position.transformNodeZ.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodePosition',{attrs:{"scene":_vm.scene,"transformNodeChild":transformNode,"x":position.x,"y":position.y,"z":position.z},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return undefined}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})}),_c('ReadVoxel',{key:_vm.positionToStr(_vm.blockLast) + "blockLast",attrs:{"boxes":_vm.block,"scene":_vm.scene,"pivotPoint":_vm.blockLast.pivotPoint,"rotation":_vm.blockLast.rotation,"shadowGenerator":shadowGenerator,"isPickable":true,"texture":_vm.texture,"uvs":_vm.uvs},on:{"pick":function (event) { return _vm.blocksUpdate(_vm.blockLast)(event); },"pickDown":function (event) { return _vm.blockNextUpdate(_vm.blockLast)(event); },"pickUp":function($event){_vm.blockNext = null},"pickOut":function($event){_vm.blockNext = null}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rotatedMesh = ref.mesh;
var transformNode = ref.transformNode;
return [_c('RotatorY',{attrs:{"mesh":rotatedMesh,"rotation":_vm.blockLast.transformNodeY.rotation,"scene":_vm.scene,"shadowGenerator":shadowGenerator,"transformNodeChild":transformNode},on:{"update:transformNodeJson":function (transformNodeJson) { return _vm.setTransformNodeY(_vm.blockLast)(transformNodeJson); }}}),_c('RotatorZ',{attrs:{"mesh":rotatedMesh,"rotation":_vm.blockLast.transformNodeZ.rotation,"scene":_vm.scene,"shadowGenerator":shadowGenerator,"transformNodeChild":transformNode},on:{"update:transformNodeJson":function (transformNodeJson) { return _vm.setTransformNodeZ(_vm.blockLast)(transformNodeJson); }}}),_c('TransformNodeRotationY',{attrs:{"pivotPoint":_vm.blockLast.transformNodeY.pivotPoint,"rotation":_vm.blockLast.transformNodeY.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodeRotationZ',{attrs:{"pivotPoint":_vm.blockLast.transformNodeZ.pivotPoint,"rotation":_vm.blockLast.transformNodeZ.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodePosition',{attrs:{"scene":_vm.scene,"transformNodeChild":transformNode,"x":_vm.blockLast.x,"y":_vm.blockLast.y,"z":_vm.blockLast.z},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return undefined}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)}),(_vm.blockNext)?_c('ReadVoxel',{attrs:{"boxes":_vm.block,"scene":_vm.scene,"pivotPoint":_vm.blockNext.pivotPoint,"rotation":_vm.blockNext.rotation,"shadowGenerator":shadowGenerator,"isPickable":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodeRotationY',{attrs:{"pivotPoint":_vm.blockNext.transformNodeY.pivotPoint,"rotation":_vm.blockNext.transformNodeY.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodeRotationZ',{attrs:{"pivotPoint":_vm.blockNext.transformNodeZ.pivotPoint,"rotation":_vm.blockNext.transformNodeZ.rotation,"scene":_vm.scene,"transformNodeChild":transformNode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return [_c('TransformNodePosition',{attrs:{"scene":_vm.scene,"transformNodeChild":transformNode,"x":_vm.blockNext.x,"y":_vm.blockNext.y,"z":_vm.blockNext.z},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transformNode = ref.transformNode;
return undefined}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)}):_vm._e()]}}],null,true)})]}}],null,true)})]}}],null,false,3210589951)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }