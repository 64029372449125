<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const created = async function () {
  this.mesh = BABYLON.MeshBuilder.CreateBox
  (
    this.uniqueId(),
    { size: 1 },
    this.scene,
  );

  this.mesh.position = new BABYLON.Vector3(this.x, this.y, this.z);

  const material = new BABYLON.StandardMaterial('material', this.scene);

  material.diffuseColor = BABYLON.Color4.FromInts
  (
    this.color.r,
    this.color.g,
    this.color.b,
    this.color.a,
  );

  material.wireframe = false;

  this.mesh.material = material;

  this.mesh.checkCollisions = true;

  /* const positions = this.mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  const indices = this.mesh.getIndices();

  const positionsNew = fp.pipe
  ([
    fp.chunk(3),
    fp.map(JSON.stringify),
    fp.uniq,
    fp.map(JSON.parse),
    fp.flatten,
  ])
  (positions);

  const indicesNew = fp.map(fp.pipe([
    fp.nth(fp.placeholder, fp.chunk(3)(positions)),
    JSON.stringify,
    fp.eq,
    fp.concat([JSON.stringify]),
    fp.pipe,
    fp.findIndex(fp.placeholder, fp.chunk(3)(positionsNew)),
  ]))
  (indices); */

  if (this.parent) {
    this.mesh.parent = this.parent;
  }

  this.$emit('mesh', this.mesh);
};

const destroyed = function () {
  this.mesh.dispose();
};

export default {
  created,
  data() {
    return {
      mesh: null
    };
  },
  destroyed,
  props: {
    scene: {
      required: true
    },
    x: {
      default: 0,
      required: false,
    },
    y: {
      default: 0,
      required: false,
    },
    z: {
      default: 0,
      required: false,
    },
    color: {
      required: true,
    },
    parent: {
      required: false,
    },
  }
}
</script>