<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const mounted = function ()
{
  this.mesh = BABYLON.MeshBuilder.CreateGround
  (
    'ground',
    {
      width: 200,
      height: 200,
    },
    this.scene,
  );

  this.mesh.checkCollisions = true;
  this.mesh.receiveShadows = true;
};

const destroyed = function ()
{
  this.mesh.dispose();
};

export default
{
  mounted,
  data()
  {
    return {
      mesh: null
    };
  },
  destroyed,
  props:
  {
    scene: { required: true }
  }
}
</script>