<template lang='pug'>
  div(style='display:none')
    v-dialog(
      v-model='dialog'
      fullscreen
    )
      v-color-picker(
        @update:color='updateColor'
      )
    Box(
      :scene='scene'
      :x='x'
      :y='y'
      :z='z'
      :color='{ r: 255, g: 0, b: 0, a: 255 }'
      @mesh='setTargetMeshId'
    )
    PickClick(
      @result='pickClickHandler'
      :scene='scene'
    )
    slot(
      :r='r'
      :g='g'
      :b='b'
    )
</template>

<script>
// import * as BABYLON from 'babylonjs';

import Box from '@/components/Box.vue';
import PickClick from '@/components/PickClick.vue';

import get from 'lodash/fp/get';

const created = async function () {
  //
};

const destroyed = function () {
  //
};

const setTargetMeshId = function (mesh) {
  this.targetMeshId = mesh.id;
};

const pickClickHandler = function (event) {
  this.dialog = get('pickedMesh.id')(event) === this.targetMeshId;
};

const updateColor = function (color) {
  this.r = color.rgba.r;
  this.g = color.rgba.g;
  this.b = color.rgba.b;
};

export default {
  components: {
    Box,
    PickClick,
  },
  created,
  data() {
    return {
      dialog: false,
      targetMeshId: null,
      r: this.rProp,
      g: this.gProp,
      b: this.bProp,
    };
  },
  destroyed,
  methods: {
    pickClickHandler,
    setTargetMeshId,
    updateColor,
  },
  props: {
    scene: {
      required: true
    },
    x: {
      default: 0,
      required: false,
    },
    y: {
      default: 0,
      required: false,
    },
    z: {
      default: 0,
      required: false,
    },
    rProp: {
      default: 0,
      required: false,
    },
    gProp: {
      default: 0,
      required: false,
    },
    bProp: {
      default: 0,
      required: false,
    },
  }
}
</script>