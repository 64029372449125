<template lang='pug'>
  div(style='display:none')
    slot(
      v-if='shadowGenerator'
      :shadowGenerator='shadowGenerator'
    )
</template>

<script>
import * as BABYLON from 'babylonjs';

const mounted = async function ()
{
  this.light = new BABYLON.DirectionalLight
  (
    this.uniqueId(),
    new BABYLON.Vector3(this.x, this.y, this.z),
    this.scene,
  );

  this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light);

  this.shadowGenerator.darkness = 0.9;
};

const destroyed = function ()
{
  this.light.dispose();
};

export default
{
  mounted,
  data()
  {
    return {
      light: null,
      shadowGenerator: null,
    };
  },
  destroyed,
  props:
  {
    scene:
    {
      required: true
    },
    x:
    {
      default: 0,
      required: false,
    },
    y:
    {
      default: -100,
      required: false,
    },
    z:
    {
      default: 0,
      required: false,
    },
  },
  watch:
  {
    x:
    {
      immediate: false,
      handler()
      {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
    y:
    {
      immediate: false,
      handler()
      {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
    z:
    {
      immediate: false,
      handler()
      {
        this.light.position.set(this.x, this.y, this.z);
      },
    },
  }
}
</script>