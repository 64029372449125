<template lang='pug'>
  div(style='display:none')
    slot(
      :rotation='rotation'
      :x='x'
      :z='z'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import * as BABYLON from 'babylonjs';

import cond from 'lodash/fp/cond';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import stubTrue from 'lodash/fp/stubTrue';

const mounted = function ()
{
  this.transformNode.rotation.y = this.rotationProp;

  this.scene.registerAfterRender(() => {
    this.z += this.w ? this.step : 0;
    this.x -= this.a ? this.step : 0;
    this.z -= this.s ? this.step : 0;
    this.x += this.d ? this.step : 0;
  });
};

const degreesTo = cond
([
  [({ w, a, s, d }) => w && d, () => 45 * 1],
  [({ w, a, s, d }) => s && d, () => 45 * 3],
  [({ w, a, s, d }) => w && a, () => 45 * 1 * -1],
  [({ w, a, s, d }) => s && a, () => 45 * 3 * -1],
  [get('w'), () => 45 * 0],
  [get('d'), () => 45 * 2],
  [get('s'), () => 45 * 4],
  [get('a'), () => 45 * 2 * -1],
  [stubTrue, get('rotationDegrees')],
]);

const handler = function ()
{
  this.scene.stopAnimation(this.transformNode);

  const frameRate = 30;

  const animation = new BABYLON.Animation
  (
    this.uniqueId(),
    'rotation.y',
    frameRate,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  {
    const degrees = BABYLON.Tools.ToDegrees(this.transformNode.rotation.y);

    this.transformNode.rotation.y = degrees > 180 ? BABYLON.Tools.ToRadians((360 - degrees) * -1) : this.transformNode.rotation.y;
  }

  const keyFrames =
  [
    {
      frame: 0,
      value: this.radiansFrom,
    },
    {
      frame: 6,
      value: this.radiansTo,
    },
  ];

  animation.setKeys(keyFrames);

  this.scene.beginDirectAnimation
  (
    this.transformNode,
    [animation],
    0,
    6,
  );
};

const radiansFrom = function ()
{
  if (Math.abs(this.degreesTo - this.rotationDegrees) > 180)
  {
    return this.degreesTo < 0 ? this.transformNode.rotation.y : BABYLON.Tools.ToRadians(360 + this.rotationDegrees);
  }
  else
  {
    return this.transformNode.rotation.y;
  }
};

const radiansTo = function ()
{
  if (Math.abs(this.degreesTo - this.rotationDegrees) > 180)
  {
    return BABYLON.Tools.ToRadians
    (
      this.degreesTo < 0 ? 360 + this.degreesTo : this.degreesTo
    );
  }
  else
  {
    return BABYLON.Tools.ToRadians(this.degreesTo);
  }
};

const rotation = function ()
{
  const degrees = BABYLON.Tools.ToDegrees(this.transformNode.rotation.y);

  return degrees > 180 ? BABYLON.Tools.ToRadians((360 - degrees) * -1) : this.transformNode.rotation.y;
};

const rotationDegrees = pipe
([
  get('rotation'),
  result => BABYLON.Tools.ToDegrees(result),
]);

export default
{
  computed:
  {
    degreesTo,
    radiansFrom,
    radiansTo,
    rotation,
    rotationDegrees,
  },
  data()
  {
    return {
      transformNode: new BABYLON.TransformNode(this.uniqueId()),
      x: this.xProp,
      z: this.zProp,
    };
  },
  mounted,
  props:
  {
    scene: { required: true },
    w:
    {
      default: false,
      required: true,
    },
    a:
    {
      default: false,
      required: true,
    },
    s:
    {
      default: false,
      required: true,
    },
    d:
    {
      default: false,
      required: true,
    },
    xProp:
    {
      default: 0,
      required: false,
    },
    zProp:
    {
      default: 0,
      required: false,
    },
    rotationProp:
    {
      default: 0,
      required: false,
    },
    step:
    {
      default: 0.1,
      required: false,
    },
  },
  watch:
  {
    w: { handler },
    a: { handler },
    s: { handler },
    d: { handler },
    rotation(value)
    {
      this.$emit('update:rotation', value);
    },
  },
}
</script>