<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const created = function () {
  this.camera = new BABYLON.UniversalCamera
  (
    this.uniqueId(),
    new BABYLON.Vector3(-150, 150, -150),
    this.scene,
  );

  this.camera.ellipsoid = new BABYLON.Vector3(10, 10, 10);

  this.camera.checkCollisions = true;

  this.camera.applyGravity = false;

  this.camera.setTarget(BABYLON.Vector3.Zero());

  // this.camera.lockedTarget = BABYLON.Vector3.Zero();

  // this.camera.speed = 20;

  this.camera.attachControl(this.canvas, true);
};

const destroyed = function () {
  this.camera.dispose();
};

export default {
  created,
  data() {
    return {
      camera: null
    };
  },
  destroyed,
  props: {
    canvas: { required: true },
    scene: { required: true },
  },
}
</script>