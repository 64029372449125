<style scoped>
.templateRoot, #renderCanvas {
  width: 100%;
  height: 100%;
}
#renderCanvas {
  touch-action: none;
}
</style>

<template lang='pug'>
  .templateRoot
    v-navigation-drawer(
      app
      dark
      mini-variant
      permanent
    )
      v-list
        v-list-item(
          link
          @click='mode = "plus"'
        )
          v-list-item-icon
            v-icon(:class='{ "pink--text": mode === "plus" }') mdi-plus
          v-list-item-content
            v-list-item-title
        v-list-item(
          link
          @click='mode = "minus"'
        )
          v-list-item-icon
            v-icon(:class='{ "pink--text": mode === "minus" }') mdi-minus
          v-list-item-content
            v-list-item-title
    canvas#renderCanvas(
      touch-action='none'
      ref='canvas'
    )
    div(
      v-if='scene'
      style='display:none'
    )
      PickClick(
        :scene='scene'
        @result=''
      )
      CameraUniversal(
        :canvas='canvas'
        :scene='scene'
      )
      //- Ground(
      //-   :scene='scene'
      //- )
      Wasd(
        :scene='scene'
        v-slot='{ w, a, s, d }'
      )
        Position(
          :scene='scene'
          :w='w'
          :a='a'
          :s='s'
          :d='d'
          :step='0.5'
          v-slot='{ x, z }'
        )
          HemisphericLight(
            :scene='scene'
            :x='-1'
            :y='1'
            :z='0'
          )
          LightDirectional(
            :scene='scene'
            v-slot='{ shadowGenerator }'
          )
            //- ReadVoxel(
            //-   :boxes='chr_knight'
            //-   :scene='scene'
            //-   :x='x'
            //-   :z='z'
            //-   :rotation='rotation'
            //-   :shadowGenerator='shadowGenerator'
            //- )
            ReadVoxel(
              v-for='position in cinderBlocksInitial'
              :key='positionToStr(position)'
              :boxes='block'
              :scene='scene'
              :pivotPoint='position.pivotPoint'
              :rotation='position.rotation'
              :shadowGenerator='shadowGenerator'
              :isPickable='true'
              :texture='texture'
              :uvs='uvs'
              @pick='event => blocksUpdate(position)(event)'
              @pickDown='event => blockNextUpdate(position)(event)'
              @pickUp='blockNext = null'
              @pickOut='blockNext = null'
              v-slot='{ transformNode }'
            )
              TransformNodeRotationY(
                :pivotPoint='position.transformNodeY.pivotPoint'
                :rotation='position.transformNodeY.rotation'
                :scene='scene'
                :transformNodeChild='transformNode'
                v-slot='{ transformNode }'
              )
                TransformNodeRotationZ(
                  :pivotPoint='position.transformNodeZ.pivotPoint'
                  :rotation='position.transformNodeZ.rotation'
                  :scene='scene'
                  :transformNodeChild='transformNode'
                  v-slot='{ transformNode }'
                )
                  TransformNodePosition(
                    :scene='scene'
                    :transformNodeChild='transformNode'
                    :x='position.x'
                    :y='position.y'
                    :z='position.z'
                    v-slot='{ transformNode }'
                  )
            ReadVoxel(
              :key='positionToStr(blockLast) + "blockLast"'
              :boxes='block'
              :scene='scene'
              :pivotPoint='blockLast.pivotPoint'
              :rotation='blockLast.rotation'
              :shadowGenerator='shadowGenerator'
              :isPickable='true'
              :texture='texture'
              :uvs='uvs'
              @pick='event => blocksUpdate(blockLast)(event)'
              @pickDown='event => blockNextUpdate(blockLast)(event)'
              @pickUp='blockNext = null'
              @pickOut='blockNext = null'
              v-slot='{ mesh: rotatedMesh, transformNode }'
            )
              //- Rotator(
              //-   :rotatedMesh='rotatedMesh'
              //-   :transformNode='transformNode'
              //-   :rotationProp='blockLast.rotation'
              //-   :scene='scene'
              //-   :shadowGenerator='shadowGenerator'
              //-   @update:metadata='applyMetadata'
              //- )
              RotatorY(
                :mesh='rotatedMesh'
                :rotation='blockLast.transformNodeY.rotation'
                :scene='scene'
                :shadowGenerator='shadowGenerator'
                :transformNodeChild='transformNode'
                @update:transformNodeJson='transformNodeJson => setTransformNodeY(blockLast)(transformNodeJson)'
              )
              RotatorZ(
                :mesh='rotatedMesh'
                :rotation='blockLast.transformNodeZ.rotation'
                :scene='scene'
                :shadowGenerator='shadowGenerator'
                :transformNodeChild='transformNode'
                @update:transformNodeJson='transformNodeJson => setTransformNodeZ(blockLast)(transformNodeJson)'
              )
              TransformNodeRotationY(
                :pivotPoint='blockLast.transformNodeY.pivotPoint'
                :rotation='blockLast.transformNodeY.rotation'
                :scene='scene'
                :transformNodeChild='transformNode'
                v-slot='{ transformNode }'
              )
                TransformNodeRotationZ(
                  :pivotPoint='blockLast.transformNodeZ.pivotPoint'
                  :rotation='blockLast.transformNodeZ.rotation'
                  :scene='scene'
                  :transformNodeChild='transformNode'
                  v-slot='{ transformNode }'
                )
                  TransformNodePosition(
                    :scene='scene'
                    :transformNodeChild='transformNode'
                    :x='blockLast.x'
                    :y='blockLast.y'
                    :z='blockLast.z'
                    v-slot='{ transformNode }'
                  )
            ReadVoxel(
              v-if='blockNext'
              :boxes='block'
              :scene='scene'
              :pivotPoint='blockNext.pivotPoint'
              :rotation='blockNext.rotation'
              :shadowGenerator='shadowGenerator'
              :isPickable='false'
              v-slot='{ transformNode }'
            )
              TransformNodeRotationY(
                :pivotPoint='blockNext.transformNodeY.pivotPoint'
                :rotation='blockNext.transformNodeY.rotation'
                :scene='scene'
                :transformNodeChild='transformNode'
                v-slot='{ transformNode }'
              )
                TransformNodeRotationZ(
                  :pivotPoint='blockNext.transformNodeZ.pivotPoint'
                  :rotation='blockNext.transformNodeZ.rotation'
                  :scene='scene'
                  :transformNodeChild='transformNode'
                  v-slot='{ transformNode }'
                )
                  TransformNodePosition(
                    :scene='scene'
                    :transformNodeChild='transformNode'
                    :x='blockNext.x'
                    :y='blockNext.y'
                    :z='blockNext.z'
                    v-slot='{ transformNode }'
                  )
</template>

<script>
/* eslint-disable no-unused-vars */

import fp from 'lodash/fp';

import ModalBox from '@/components/ModalBox.vue';
import CameraUniversal from '@/components/CameraUniversal.vue';
import Ground from '@/components/Ground.vue';
import HemisphericLight from '@/components/HemisphericLight.vue';
import LightDirectional from '@/components/LightDirectional.vue';
import LightPoint from '@/components/LightPoint.vue';
import PickClick from '@/components/PickClick.vue';
import PickMouseMove from '@/components/PickMouseMove.vue';
import Position from '@/components/Position.vue';
import ReadVoxel from '@/components/ReadVoxel.vue';
import Rotator from '@/components/Rotator.vue';
import Sphere from '@/components/Sphere.vue';
import Wasd from '@/components/Wasd.vue';
import RotatorY from '@/components/RotatorY.vue';
import RotatorZ from '@/components/RotatorZ.vue';
import TransformNodePosition from '@/components/TransformNodePosition.vue';
import TransformNodeRotationY from '@/components/TransformNodeRotationY.vue';
import TransformNodeRotationZ from '@/components/TransformNodeRotationZ.vue';

import * as BABYLON from 'babylonjs';

const TwoByFour = require('../../TwoByFour.json');
const block = require('../../block.json');
// const block = TwoByFour;
const block2 = require('../../block2.json');
const block3 = require('../../block3.json');
const blockControl = require('../../blockControl.json');
const castle = require('../../castle.json');
const chr_knight = require('../../chr_knight.json');
const chr_sword = require('../../chr_sword.json');
const monu10 = require('../../monu10.json');
const uvs = require('../../block-uvs.json');
const ModelRotator = require('../../ModelRotator.json');

const mounted = async function ()
{
  await new Promise(resolve => {
    if (document.readyState === 'complete')
    {
      resolve();
    }
    else
    {
      window.addEventListener('DOMContentLoaded', resolve);
    }
  });

  this.canvas = this.$refs.canvas;

  const engine = new BABYLON.Engine(this.canvas, true);

  this.scene = new BABYLON.Scene(engine);

  this.scene.clearColor = BABYLON.Color3.FromInts(54, 54, 54);

  this.scene.gravity = new BABYLON.Vector3(0, -9.81, 0);

  this.scene.collisionsEnabled = true;

  // this.scene.debugLayer.show();

  engine.runRenderLoop(() => {
    this.scene.render();

    // console.log(engine.getFps().toFixed());
  });

  this.texture =
  {
    bumpTexture: 'pkngj_4K_Bump.jpg',
    diffuseTexture: 'pkngj_4K_Albedo.jpg',
  };

  window.addEventListener('resize', () => engine.resize());
};

const destroyed = function ()
{
  this.log('destroyed');
};

const positionToStr = fp.pipe
([
  fp.at
  ([
    'x',
    'y',
    'z',
    'transformNodeY.rotation.y',
    'transformNodeZ.rotation.z',
  ]),
  JSON.stringify,
]);

const eventToBlock = function (metadata)
{
  return event =>
  {
    const round = fp.pipe
    ([
      fp.multiply(10),
      Math.round,
      fp.divide(fp.placeholder, 10),
    ]);

    const toPlane = pointA => pointB =>
    {
      return fp.pipe
      ([
        fp.keys,
        fp.reject(key => round(pointA[key] - pointB[key])),
        fp.head,
      ])
      (pointA);
    };

    const eventToIntersection = event =>
    {
      return fp.pipe
      ([
        fp.get('pickedMesh'),
        fp.invoke('getBoundingInfo'),
        fp.get('boundingBox'),
        fp.pick(['minimumWorld', 'maximumWorld']),
        fp.mapValues(toPlane(event.pickedPoint)),
        JSON.stringify,
        JSON.parse,
      ])
      (event);
    };

    const eventToIntersectionMemoized = fp.memoize(eventToIntersection);

    const eventToDirection = fp.pipe
    ([
      eventToIntersectionMemoized,
      fp.values,
      fp.head,
    ]);

    const eventToDelta = event =>
    {
      return fp.pipe
      ([
        fp.get('pickedMesh'),
        fp.invoke('getBoundingInfo'),
        fp.get('boundingBox.extendSizeWorld'),
        fp.mapValues(fp.multiply(2)),
        fp.mapValues(Math.round),
        fp.get(eventToDirection(event)),
        fp.multiply(eventToIntersectionMemoized(event).minimumWorld ? -1: 1),
      ])
      (event);
    };

    return fp.pipe
    ([
      fp.update
      (eventToDirection(event))
      (fp.add(eventToDelta(event))),
    ])
    (metadata);
  };
};

const blocksUpdate = function (metadata)
{
  return event =>
  {
    fp.pipe
    ([
      this.eventToBlock(metadata),
      fp.cond
      ([
        [
          fp.wrap(null)(this.mode === 'minus'),
          () => this.$store.commit('cinderBlockDelete', metadata),
        ],
        [
          fp.pipe
          ([
            this.positionToStr,
            fp.eq,
            fp.concat(this.positionToStr),
            fp.pipe,
            fp.find(fp.placeholder, this.cinderBlocks),
            fp.negate(fp.identity),
          ]),
          result => this.$store.commit('cinderBlockAdd', result),
        ],
      ]),
    ])
    (event);
  };
};

const blockLast = function ()
{
  return this.$store.getters.cinderBlockLast;
};

const cinderBlocks = function ()
{
  return this.$store.state.cinderBlocks;
};

const cinderBlocksInitial = function ()
{
  return this.$store.getters.cinderBlocksInitial;
};

const blockNextUpdate = function (metadata)
{
  return event =>
  {
    fp.cond
    ([[
      fp.pipe([fp.get('mode'), fp.eq('plus')]),
      () => this.blockNext = this.eventToBlock(metadata)(event),
    ]])
    (this);
  };
};

const applyMetadata = function (metadata)
{
  this.$store.commit('setRotation', metadata.rotation);
  this.$store.commit('setPivotPoint', metadata.pivotPoint);
};

const setTransformNodeY = function (metadata)
{
  return transformNodeY =>
  {
    this.$store.commit('setTransformNodeY', transformNodeY);
  };
};

const setTransformNodeZ = function (metadata)
{
  return transformNodeZ =>
  {
    this.$store.commit('setTransformNodeZ', transformNodeZ);
  };
};

export default
{
  components:
  {
    CameraUniversal,
    Ground,
    HemisphericLight,
    LightDirectional,
    LightPoint,
    ModalBox,
    PickClick,
    PickMouseMove,
    Position,
    ReadVoxel,
    Rotator,
    RotatorY,
    RotatorZ,
    Sphere,
    TransformNodePosition,
    TransformNodeRotationY,
    TransformNodeRotationZ,
    Wasd,
  },
  computed:
  {
    blockLast,
    cinderBlocks,
    cinderBlocksInitial,
  },
  data()
  {
    return {
      block,
      block2,
      block3,
      blockControl,
      blockNext: null,
      canvas: null,
      castle,
      chr_knight,
      chr_sword,
      ModelRotator,
      mode: 'plus',
      monu10,
      scene: null,
      texture: null,
      TwoByFour,
      uvs,
    };
  },
  destroyed,
  methods:
  {
    applyMetadata,
    blockNextUpdate,
    blocksUpdate,
    eventToBlock,
    positionToStr,
    setTransformNodeY,
    setTransformNodeZ,
  },
  mounted,
}
</script>