<template lang='pug'>
  div
    slot(
      v-if='mesh && transformNode'
      :mesh='mesh'
      :transformNode='transformNode'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import * as BABYLON from 'babylonjs';

import fp from 'lodash/fp';

const mounted = function ()
{
  const {
    color,
    indices,
    positions,
  } = this.boxes[0];

  const indexToPosition = fp.pipe
  ([
    fp.chunk(3),
    result => fp.get(fp.placeholder, result),
  ])
  (positions);

  const vertexData = new BABYLON.VertexData();

  vertexData.positions = positions;

  vertexData.indices = indices;

  if (this.uvs)
  {
    vertexData.uvs = this.uvs;
  }

  this.mesh = new BABYLON.Mesh(this.uniqueId(), this.scene);

  vertexData.applyToMesh(this.mesh);

  if (this.texture)
  {
    const texture = new BABYLON.StandardMaterial
    (
      this.uniqueId(),
      this.scene,
    );

    texture.diffuseColor = new BABYLON.Color3(0.9, 0.9, 0.9);

    texture.diffuseTexture = new BABYLON.Texture
    (
      this.texture.diffuseTexture,
      this.scene,
    );

    texture.bumpTexture = new BABYLON.Texture
    (
      this.texture.bumpTexture,
      this.scene,
    );

    this.mesh.material = texture;
  }
  else
  {
    const material = new BABYLON.StandardMaterial(this.uniqueId(), this.scene);

    material.diffuseColor = new BABYLON.Color3(1, 0, 0);

    this.mesh.material = material;
  }

  this.mesh.parent = this.transformNode;

  this.mesh.receiveShadows = true;

  this.mesh.isPickable = this.isPickable;

  this.shadowGenerator.getShadowMap().renderList.push(this.mesh);

  this.mesh.actionManager = new BABYLON.ActionManager(this.scene);

  this.mesh.checkCollisions = true;

  fp.pipe
  ([
    fp.get('mesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize'),
    fp.assign(fp.placeholder, { y: 0 }),
    pivotPoint => this.transformNode.setPivotPoint(pivotPoint),
  ])
  (this);

  fp.pipe
  ([
    fp.get('pivotPoint'),
    fp.cond
    ([[
      fp.identity,
      pivotPoint => this.transformNode.setPivotPoint(pivotPoint),
    ]]),
  ])
  (this);

  this.$emit('update:mesh', this.mesh);
  this.$emit('update:transformNode', this.transformNode);

  const OnPickTrigger = fp.pipe
  ([
    fp.pick(['pointerX', 'pointerY']),
    ({ pointerX, pointerY }) => this.scene.pick(pointerX, pointerY),
    result => this.$emit('pick', result),
  ]);

  const OnPickDownTrigger = fp.pipe
  ([
    fp.pick(['pointerX', 'pointerY']),
    ({ pointerX, pointerY }) => this.scene.pick(pointerX, pointerY),
    result => this.$emit('pickDown', result),
  ]);

  const OnPickUpTrigger = fp.pipe
  ([
    fp.pick(['pointerX', 'pointerY']),
    ({ pointerX, pointerY }) => this.scene.pick(pointerX, pointerY),
    result => this.$emit('pickUp', result),
  ]);

  const OnPickOutTrigger = fp.pipe
  ([
    fp.pick(['pointerX', 'pointerY']),
    ({ pointerX, pointerY }) => this.scene.pick(pointerX, pointerY),
    result => this.$emit('pickOut', result),
  ]);

  this.mesh.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnPickTrigger,
      OnPickTrigger,
    )
  );

  this.mesh.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnPickDownTrigger,
      OnPickDownTrigger,
    )
  );

  this.mesh.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnPickUpTrigger,
      OnPickUpTrigger,
    )
  );

  this.mesh.actionManager.registerAction
  (
    new BABYLON.ExecuteCodeAction
    (
      BABYLON.ActionManager.OnPickOutTrigger,
      OnPickOutTrigger,
    )
  );
};

const destroyed = function ()
{
  fp.pipe
  ([
    fp.get('transformNode'),
    fp.invoke('getChildMeshes'),
    fp.map('material'),
    fp.map(fp.invoke('dispose')),
  ])
  (this);

  fp.pipe
  ([
    fp.get('transformNode'),
    fp.invoke('getChildMeshes'),
    fp.map(fp.invoke('dispose')),
  ])
  (this);

  fp.pipe
  ([
    fp.get('transformNode'),
    fp.invoke('dispose'),
  ])
  (this);
};

export default {
  mounted,
  data() {
    return {
      mesh: null,
      transformNode: new BABYLON.TransformNode(this.uniqueId(), this.scene),
    };
  },
  destroyed,
  props: {
    boxes: { required: true },
    pivotPoint: { required: false },
    scene: { required: true },
    shadowGenerator: { required: true },
    texture: { required: false },
    uvs: { required: false },
    rotation: {
      default: 0,
      required: false,
    },
    x: {
      default: 0,
      required: false,
    },
    y: {
      default: 0,
      required: false,
    },
    z: {
      default: 0,
      required: false,
    },
    isPickable:
    {
      default: true,
      required: true,
    },
  },
  watch: {
    rotation: {
      immediate: true,
      handler() {
        this.transformNode.rotation.y = this.rotation;
      },
    },
    x: {
      immediate: true,
      handler() {
        this.transformNode.position.x = this.x;
      },
    },
    y: {
      immediate: true,
      handler() {
        this.transformNode.position.y = this.y;
      },
    },
    z: {
      immediate: true,
      handler() {
        this.transformNode.position.z = this.z;
      },
    },
  },
}
</script>