<template lang='pug'>
  div(style='display:none')
</template>

<script>
const listener = function () {
  this.$emit('result', this.scene.pick(this.scene.pointerX, this.scene.pointerY));
};

const created = async function () {
  window.addEventListener('mousemove', listener.bind(this));
};

const destroyed = function () {
  window.removeEventListener('mousemove', listener.bind(this));
};

export default {
  created,
  destroyed,
  props: {
    scene: {
      required: true
    },
  }
}
</script>