<template lang='pug'>
  div(style='display:none')
</template>

<script>
import * as BABYLON from 'babylonjs';

const created = async function () {
  this.mesh = BABYLON.MeshBuilder.CreateSphere
  (
    'sphere',
    { diameter: 1 },
    this.scene,
  );
};

const destroyed = function () {
  this.mesh.dispose();
};

export default {
  created,
  data() {
    return {
      mesh: null
    };
  },
  destroyed,
  props: {
    scene: { required: true }
  }
}
</script>