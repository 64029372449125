import fp from 'lodash/fp';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence
({
  storage: window.localStorage
});

const positionToStr = fp.pipe
([
  fp.at(['x', 'y', 'z']),
  JSON.stringify,
]);

const cinderBlockAdd = (state, payload) =>
{
  state.cinderBlocks = fp.pipe
  ([
    fp.concat(state.cinderBlocks),
    JSON.stringify,
    JSON.parse,
  ])
  (payload);
};

const cinderBlockDelete = (state, payload) =>
{
  state.cinderBlocks = fp.pipe
  ([
    positionToStr,
    fp.eq,
    fp.concat(positionToStr),
    fp.pipe,
    fp.reject(fp.placeholder, state.cinderBlocks),
    JSON.stringify,
    JSON.parse,
  ])
  (payload);
};

const cinderBlockLast = state =>
{
  return fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.last,
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

const cinderBlocksInitial = state =>
{
  return fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.initial,
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

const setRotation = (state, rotation) =>
{
  state.cinderBlocks = fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.last,
    fp.assign(fp.placeholder, { rotation }),
    fp.concat(cinderBlocksInitial(state)),
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

const setPivotPoint = (state, pivotPoint) =>
{
  state.cinderBlocks = fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.last,
    fp.assign(fp.placeholder, { pivotPoint }),
    fp.concat(cinderBlocksInitial(state)),
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

const setTransformNodeY = (state, transformNodeY) =>
{
  state.cinderBlocks = fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.last,
    fp.assign(fp.placeholder, { transformNodeY }),
    fp.concat(cinderBlocksInitial(state)),
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

const setTransformNodeZ = (state, transformNodeZ) =>
{
  state.cinderBlocks = fp.pipe
  ([
    fp.get('cinderBlocks'),
    fp.last,
    fp.assign(fp.placeholder, { transformNodeZ }),
    fp.concat(cinderBlocksInitial(state)),
    JSON.stringify,
    JSON.parse,
  ])
  (state);
};

export default new Vuex.Store
({
  state:
  {
    cinderBlocks:
    [{
      x: 0,
      y: 0,
      z: 0,
      rotation: 0,
      pivotPoint:
      {
        x: 0,
        y: 0,
        z: 0,
      },
      transformNodeY:
      {
        pivotPoint:
        {
          x: 0,
          y: 0,
          z: 0,
        },
        rotation:
        {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      transformNodeZ:
      {
        pivotPoint:
        {
          x: 0,
          y: 0,
          z: 0,
        },
        rotation:
        {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    }]
  },
  mutations:
  {
    cinderBlockAdd,
    cinderBlockDelete,
    setPivotPoint,
    setRotation,
    setTransformNodeY,
    setTransformNodeZ,
  },
  getters:
  {
    cinderBlockLast,
    cinderBlocksInitial,
  },
  actions:
  {
  },
  modules:
  {
  },
  plugins:
  [
    vuexLocal.plugin
  ],
});
