<template lang='pug'>
  div(style='display:none')
    Position(
      :scene='scene'
      :w='w'
      :a='a'
      :s='s'
      :d='d'
      v-bind:rotation.sync='rotation'
    )
    ReadVoxel(
      :boxes='ModelRotator'
      :isPickable='true'
      :scene='scene'
      :x='x'
      :z='z'
      :rotation='rotation'
      :shadowGenerator='shadowGenerator'
      v-bind:mesh.sync='mesh'
      @pick='rotate1'
      @update:transformNode='setParent'
    )
    ReadVoxel(
      :boxes='ModelRotator'
      :isPickable='true'
      :scene='scene'
      :x='x2'
      :z='z'
      :rotation='rotation'
      :shadowGenerator='shadowGenerator'
      @pick='rotate2'
      @update:transformNode='setParent'
    )
</template>

<script>
/* eslint-disable no-unused-vars */

import * as BABYLON from 'babylonjs';

import fp from 'lodash/fp';

import Position from '@/components/Position.vue';
import ReadVoxel from '@/components/ReadVoxel.vue';

const ModelRotator = require('../../ModelRotator.json');

const destroyed = function ()
{
  this.log('destroyed');
};

const letter = function ()
{
  const letters =
  [
    'w',
    'a',
    's',
    'd',
  ];

  return letters[this.counter % letters.length];
};

const rotate = function ()
{
  this.counter++;

  this.w = false;
  this.a = false;
  this.s = false;
  this.d = false;

  this[this.letter] = true;
};

const rotate1 = function ()
{
  this.transformNode.setPivotPoint
  (
    new BABYLON.Vector3
    (
      this.pivotPoint1.x,
      this.pivotPoint1.y,
      this.pivotPoint1.z,
    )
  );

  this.pivotPoint = fp.pipe
  ([
    fp.get('transformNode'),
    fp.invoke('getPivotPoint'),
  ])
  (this);

  this.rotate();
};

const rotate2 = function ()
{
  this.transformNode.setPivotPoint
  (
    new BABYLON.Vector3
    (
      this.pivotPoint2.x,
      this.pivotPoint2.y,
      this.pivotPoint2.z,
    )
  );

  this.pivotPoint = fp.pipe
  ([
    fp.get('transformNode'),
    fp.invoke('getPivotPoint'),
  ])
  (this);

  this.rotate();
};

const x = function ()
{
  const extendSize = fp.pipe
  ([
    fp.get('mesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.x'),
  ])
  (this);

  return fp.pipe
  ([
    fp.get('rotatedMesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.z'),
    fp.subtract(fp.placeholder, extendSize),
  ])
  (this);
};

const x2 = function ()
{
  const extendSize = fp.pipe
  ([
    fp.get('mesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.x'),
  ])
  (this);

  return fp.pipe
  ([
    fp.get('rotatedMesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize'),
    fp.at(['x', 'z']),
    ([x, z]) => x * 2 - z - extendSize,
  ])
  (this);
};

const z = function ()
{
  const extendSize = fp.pipe
  ([
    fp.get('mesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.z'),
  ])
  (this);

  return fp.pipe
  ([
    fp.get('rotatedMesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.z'),
    fp.subtract(fp.placeholder, extendSize),
  ])
  (this);
};

const pivotPoint1 = function ()
{
  return fp.pipe
  ([
    fp.get('rotatedMesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize.z'),
    z => ({ x: z, y: 0, z }),
  ])
  (this);
};

const pivotPoint2 = function ()
{
  return fp.pipe
  ([
    fp.get('rotatedMesh'),
    fp.invoke('getBoundingInfo'),
    fp.get('boundingBox.extendSize'),
    fp.at(['x', 'z']),
    ([x, z]) => ({ x: x * 2 - z, y: 0, z }),
  ])
  (this);
};

const metadata = function ()
{
  return fp.pipe
  ([
    fp.at
    ([
      'pivotPoint',
      'rotation',
    ]),
    ([pivotPoint, rotation]) => fp.assign({})({ pivotPoint, rotation }),
  ])
  (this);
};

const setParent = function (transformNode)
{
  transformNode.parent = this.rotatedMesh;
};

export default
{
  components:
  {
    Position,
    ReadVoxel,
  },
  computed:
  {
    letter,
    metadata,
    pivotPoint1,
    pivotPoint2,
    x,
    x2,
    z,
  },
  data()
  {
    return {
      counter: 0,
      mesh: null,
      ModelRotator,
      pivotPoint: null,
      rotation: this.rotationProp,
      w: false,
      a: false,
      s: false,
      d: false,
    };
  },
  destroyed,
  methods:
  {
    rotate,
    rotate1,
    rotate2,
    setParent,
  },
  props:
  {
    rotatedMesh: { required: true },
    rotationProp: { required: true },
    scene: { required: true },
    shadowGenerator: { required: true },
    transformNode: { required: true },
  },
  watch:
  {
    rotation(value)
    {
      this.$emit('update:rotation', value);
      this.$emit('update:metadata', this.metadata);
    }
  },
}
</script>